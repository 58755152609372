import { useEffect } from 'react';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';

import { CustomTable } from '@/ui/components/CustomTable/CustomTable';
import { ReviewAndQuestionsFilter } from '@/ui/components/ReviewAndQuestionsFilter/ReviewAndQuestionsFilter';
import {
  useReviewProductConfig,
  useReviewsProductsTab,
} from '@/ui/containers/Reviews/listing/tabs/ProductsTab/ReviewsProductsTab.hooks';

import { CreateFilterSlice } from '@/store/slices/banners/bannersSlice';
import {
  reviewsProductsFilterActions,
  reviewsProductsFilterSelector,
  useLazyGetReviewsProductsListQuery,
} from '@/store/slices/reviews/reviewsSlice';

export const ReviewsProductsTab = () => {
  const dispatch = useAppDispatch();
  const [get, data] = useLazyGetReviewsProductsListQuery();
  const filters = useAppSelector(reviewsProductsFilterSelector);
  const { setFilter, fullReset } = reviewsProductsFilterActions;
  const filterProps = useReviewProductConfig(get);
  const { columns } = useReviewsProductsTab(filterProps.onChange);

  useEffect(() => {
    return () => {
      dispatch(fullReset());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value: Partial<CreateFilterSlice>) => {
    dispatch(setFilter(value));
    filterProps.onChange(value);
  };

  return (
    <>
      <ReviewAndQuestionsFilter {...filterProps} />
      <CustomTable
        onChangePagination={(v) => handleChange({ currentPage: v })}
        limit={Number(filters.limit)}
        noData='К сожалению, данный раздел пуст.'
        total={data.data?.payload.meta.total}
        currentPage={filters.currentPage}
        status={data.isFetching ? 'loading' : 'loaded'}
        data={data.data?.payload?.items || []}
        columns={columns}
        noFooter={false}
      />
    </>
  );
};
